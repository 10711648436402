import { HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import Swal from 'sweetalert2';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';
import { IResponse } from 'src/app/shared/models/response/response.model';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent<T> {
    entity: T;
    entityId: any;
    loading: boolean = false;
    message: {
        success: string;
        error: string;
    } = { success: '', error: '' };
    redirectUrl: string[] = [];

    constructor(
        @Inject('GENERIC_SERVICE') public genericService: IGenericService<T>,
        public router: Router
    ) { }

    public create() {
        this.subscribeToSaveResponse(this.genericService.create(this.entity), this.message, this.redirectUrl);
    }

    public update() {
        this.subscribeToSaveResponse(this.genericService.update(this.entity, this.entityId), this.message, this.redirectUrl);
    }

    public createAsync(autoManageError: boolean = true): Observable<T> {
        return this.pipeResponse(this.genericService.createEntity(this.entity),autoManageError);
    }

    public deleteAsync(autoManageError: boolean = true): Observable<T> {
        return this.pipeResponse(this.genericService.deleteEntity(this.entityId),autoManageError);
    }

    public updateAsync(autoManageError: boolean = true): Observable<T> {
        return this.pipeResponse(this.genericService.updateEntity(this.entity, this.entityId),autoManageError);
    }

    public onSaveError(message): void {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message.error
        });
    }

    public onSaveSuccess(message): void {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: message.success,
            showConfirmButton: false,
            width: 400,
            timer: 2000
        });
    }

    public subscribeToSaveResponse(result: Observable<HttpResponse<T>>, message: {}, redirectUrl: string[]): void {
        result.subscribe({
            next: _ => {
                this.onSaveSuccess(message);
                this.router.navigate(redirectUrl);
            },
            error: _ => {
                this.onSaveError(message);
            },
        });
    }

    private pipeResponse(httpRequest: Observable<HttpResponse<IResponse<T>>>, autoManageError: boolean = true): Observable<T> {
        let response = httpRequest;
        if (autoManageError) {
            response = response.pipe(
                tap({
                    next: response => {
                        this.onSaveSuccess({ success: response.body.message });
                    },
                    error: response => {
                        this.onSaveError({ error: response.body.code });
                    }
                }),
            );
        }
        return response.pipe(
            map(response => response.body?.data as T)
        );
    }
}

