import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { IPagingFilter } from '../../../shared/models/helper/paging-filter.model';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';
import { IResponse } from 'src/app/shared/models/response/response.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class GenericService<T> implements IGenericService<T> {
    public resourceUrl: string;
    constructor(
        public httpClient: HttpClient,
        private applicationConfigService: ApplicationConfigService,
        public url: string
    ) {
        this.resourceUrl = this.applicationConfigService.getEndpointFor().concat('api/' + url);
    }

    hasPermission(permission: string): boolean {
        if (localStorage.getItem('role').includes("SUPERADMIN")) {
            return true;
        }
        if (localStorage.getItem('permissions').includes(permission)) {
            return true;
        }
        return false;
    }

    create(entity: T): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<T>(this.resourceUrl, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                    return response;
                })
            );
    }

    update(entity: T, entityId: any): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<T>(`${this.resourceUrl}/${entityId}`, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                    return response;
                })
            );
    }

    delete(entityId: number): Observable<HttpResponse<{}>> {
        return this.httpClient.delete(`${this.resourceUrl}/${entityId}`, { observe: 'response' });
    }

    getDetail(entityId: number) {
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }

    getDetailByLocale(entityId: number, i18n: string) {
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}/${i18n}`);
    }

    getDetailStr(entityId: string): Observable<T> {
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }

    getList(): Observable<T[]> {
        return this.httpClient.get<T[]>((`${this.resourceUrl}`));
    }

    // getByDomain(domainId: number): Observable<T[]> {
    //     return this.httpClient.get<T[]>(`${this.resourceUrl}?brandDomainId=${domainId}`);
    // }
    // getByDomain(domainId?: number): Observable<T[]> {
    //     let url = `${this.resourceUrl}`;
    //     if (domainId) {
    //         url += `?brandDomainId=${domainId}`;
    //     }
    //     return this.httpClient.get<T[]>(url);
    // }

    getByDomain(domainId?: number): Observable<T[]> {
        let params = new HttpParams();
        if (domainId) {
            params = params.append("brandDomainId", domainId.toString());
        }
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }

    getPaging(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
            filterParams = filterParams.set(element.field, element.value);
        });
        return this.httpClient.get<T[]>(`${this.resourceUrl}/get-paging`, {
            params: filterParams,
        });
    }

    getEntities(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
            filterParams = filterParams.set(element.field, element.value);
        });
        return this.httpClient.get<T[]>(`${this.resourceUrl}`, {
            params: filterParams,
        });
    }

    getEntityList(): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient.get<IResponse<T>>(`${this.resourceUrl}/list`, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError)
            );
    }

    getPagingWithBrandDomainIds(filters: IPagingFilter[], ids: number[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
            filterParams = filterParams.set(element.field, element.value);
        });
        let url = `${this.resourceUrl}?`;
        for (let id of ids) {
            url += `brandDomainIds=${id}&`;
        }
        return this.httpClient.get<T[]>(url, {
            params: filterParams,
        });
    }

    getPagingWithBrandDomainIdsAndCategories(
        filters: IPagingFilter[],
        ids: number[],
        categoryIds: number[]
    ): Observable<T[]> {
        let params = new HttpParams();
        filters.forEach(filter => {
            params = params.set(filter.field, filter.value);
        });
        if (ids.length > 0) {
            ids.forEach(id => {
                params = params.append('brandDomainIds', id.toString());
            });
        }
        if (categoryIds.length > 0) {
            categoryIds.forEach(id => {
                params = params.append('categoryIds', id.toString());
            });
        }
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }

    getPagingWithBrandDomainIdsAndRanges(
        filters: IPagingFilter[],
        ids: number[],
        categoryIds: number[]
    ): Observable<T[]> {
        let params = new HttpParams();
        filters.forEach(filter => {
            params = params.set(filter.field, filter.value);
        });
        if (ids.length > 0) {
            ids.forEach(id => {
                params = params.append('brandDomainIds', id.toString());
            });
        }
        if (categoryIds.length > 0) {
            categoryIds.forEach(id => {
                params = params.append('productRangeIds', id.toString());
            });
        }
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }


    // getPagingWithBrandDomainIdsAndCategories(filters: IPagingFilter[], ids: number[], categoryIds: number[]): Observable<T[]> {
    //     let filterParams: HttpParams = new HttpParams();
    //     filters.forEach(element => {
    //         filterParams = filterParams.set(element.field, element.value);
    //     });
    //     let url = `${this.resourceUrl}?`;
    //     for (let id of ids) {
    //         url += `brandDomainIds=${id}&`;
    //     }
    //     if (categoryIds.length > 0) {
    //         for (let id of categoryIds) {
    //             url += `categoryIds=${id}&`;
    //         }
    //     }
    //     return this.httpClient.get<T[]>(url, {
    //         params: filterParams,
    //     });
    // }

    public createEntity(entity: T): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient.post<IResponse<T>>(this.resourceUrl, entity, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError)
            );
    }

    public deleteEntity(entityId: number): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient.delete<IResponse<T>>(`${this.resourceUrl}/${entityId}`, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError)
            );
    }

    public updateEntity(entity: T, entityId: number): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient.put<IResponse<T>>(`${this.resourceUrl}/${entityId}`, entity, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError)
            );
    }

    private handleApiError(error: HttpErrorResponse) {
        return throwError(() => error);
    }
}