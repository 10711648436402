import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IBrandConnectedMediaFile } from '../../../shared/models/brand-connected-media-file/brand-connected-media-file.model';
import { IBrandConnectedMediaFileService } from '../../../shared/interface/brand-connected-media-file/brand-connected-media-file-interface.service';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
	providedIn: 'root',
})
export class BrandConnectedMediaFileService extends GenericService<IBrandConnectedMediaFile> implements IBrandConnectedMediaFileService {
	constructor(
		httpClient: HttpClient,
		applicationConfigService: ApplicationConfigService
	) {
		super(httpClient, applicationConfigService, "brand-connected-media-file");
	}

	uploadFile(brandDomainId: number, brandTemplateId: number | null, file: any): Observable<HttpResponse<any>> {
		const formData = new FormData();
		formData.append('file', file, file.name);
		const params: any = {
			brandDomainId: brandDomainId
		};
		if (brandTemplateId) params.brandTemplateId = brandTemplateId;
		return this.httpClient.post<any>(`${this.resourceUrl}/upload-csv`, formData, { params: params, observe: 'response' });
	}

	sendFormData(formData) {
		return this.httpClient.post<any>(this.resourceUrl + '/upload-csv', formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	getBase64FromFile(file: any): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				const base64String = reader.result as string;
				resolve(base64String);
			};
			reader.onerror = error => reject(error);
		});
	}

	deleteFromName(fileName: string): Observable<HttpResponse<{}>> {
		return this.httpClient.delete(`${this.resourceUrl}/${fileName}`, { observe: 'response' });
	}
}