import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IDomainUrl } from '../../../shared/models/domain-url/domain-url.model';
import { IDomainUrlService } from '../../../shared/interface/domain-url/domain-url-interface.service';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class DomainUrlService extends GenericService<IDomainUrl> implements IDomainUrlService {
  constructor(
    httpClient: HttpClient,
    applicationConfigService: ApplicationConfigService
  ) {
    super(httpClient, applicationConfigService, "domain-url");
  }

  getList(brandId?: number): Observable<IDomainUrl[]> {
    const params = brandId ? new HttpParams().set("brandId", brandId) : undefined;
    return this.httpClient.get<IDomainUrl[]>(`${this.resourceUrl}/list`, { params });
  }
}