import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { ICategoryService } from '../../../shared/interface/category/category-interface.service';
import { ICategory } from '../../../shared/models/category/category.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class CategoryService extends GenericService<ICategory> implements ICategoryService {
    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ) {
        super(httpClient,applicationConfigService,"category");
    }
}
